.home .video-container {
  width: 549px;
  height: 309px;
  overflow: hidden;
  border-radius: "20px";
  position: relative;
  pointer-events: none;
}
@media screen and (max-width: 600px) {
  .home .video-container {
    width: 93vw;
    height: 198px;
    margin-right: -2vh;
  }
}
.home .video-container iframe {
  position: absolute;
  border-radius: "20px";
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.home .video-container iframe {
  position: absolute;
  border-radius: "20px";
  top: -60px;
  left: 0;
  width: 100%;
  height: calc(100% + 120px);
}
.home .video-foreground {
  pointer-events: none;
  border-radius: "20px";
}

.image {
  animation-name: "arrow";
  -webkit-animation: arrow 1s infinite alternate;
  animation: arrow 1s infinite alternate;
}
@keyframes arrow {
  0% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}
