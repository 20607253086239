.product {
  display: flex;
  justify-content: center;
  /* margin-left: -2.5%; */
}

.product .video-container {
  /* width: 1248px;
  height: 700px; */
  overflow: hidden;
  border-radius: "20px";
  position: relative;
  pointer-events: none;
  border-radius: 20px;
}

/* @media screen and (max-width: 320px) {
  .product .video-container {
    width: 300px;
    height: 160px;
  }
}

@media screen and (min-width: 433px) {
  .product .video-container {
    width: 430px;
    height: 226px;
  }
} */

@media screen and (max-width: 424px) {
  .product .video-container {
    width: 350px;
    height: 199px;
  }
}

@media screen and (min-width: 424px) {
  .product .video-container {
    width: 388px;
    height: 220px;
  }
}

@media screen and (min-width: 480px) {
  .product .video-container {
    width: 440px;
    height: 245px;
  }
}

@media screen and (min-width: 480px) {
  .product .video-container {
    width: 440px;
    height: 245px;
  }
}

@media screen and (min-width: 550px) {
  .product .video-container {
    width: 500px;
    height: 253px;
  }
}

@media screen and (min-width: 550px) {
  .product .video-container {
    width: 500px;
    height: 253px;
  }
}

@media screen and (min-width: 768px) {
  .product .video-container {
    width: 700px;
    height: 396px;
  }
}

@media screen and (min-width: 925px) {
  .product .video-container {
    width: 900px;
    height: 507px;
  }
}

@media screen and (min-width: 1024px) {
  .product .video-container {
    width: 1000px;
    height: 550px;
  }
}
/* 1309 */
@media screen and (min-width: 1180px) {
  .product .video-container {
    width: 1130px;
    height: 638px;
  }
}

.product .video-container iframe {
  position: absolute;
  border-radius: "20px";
  /* left: 0; */
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.product .video-container iframe {
  position: absolute;
  border-radius: "20px";
  top: -60px;
  /* left: 0; */
  width: 100%;
  height: calc(100% + 120px);
}
.product .video-foreground {
  pointer-events: none;
  border-radius: "20px";
}
