a {
  text-decoration: none !important;
  text-decoration-line: none;
  color: initial;
  padding: unset;
}

body {
  background: initial;
  background-color: initial;
  color: initial;
}

#navbar-principal .nav-link {
  font-family: "Poppins", sans-serif !important;
  color: #192245 !important;
  font-size: 20px !important;
}

#navbar-principal .nav-link:hover,
.nav-link:focus {
  color: #909090 !important;
}
